import React, {useRef, useState, useEffect} from 'react'

import { isElementInViewport } from '../../components/functions'

export const FindWinesPerfectForAnyOccasion = ({ slice }) => {
    const [isInView, setIsInView] = useState(false)
    const ref = useRef(null)

    const data = slice.primary
    const items = slice.items

    //create an event listener
    useEffect(() => {
        if(isElementInViewport(ref.current)){
            setIsInView(isElementInViewport(ref.current))
        }
        window.addEventListener('scroll',() => {
            if(isElementInViewport(ref.current)){
                setIsInView(true)
            }
        })
    }, [])

    return(
        <section
            className={(isInView)? 'find-wines-perfect-for-occasion pb-lg-5 in-view' : 'find-wines-perfect-for-occasion pb-lg-5'}
            ref={ref}
        >
            <div className="container pb-4 mt-lg-5">
                <div className='tlt-row slide-up'>
                    <div className="d-flex align-items-center">
                        <div className='desc-wrapper text-left w-100 pt-3 pt-lg-0'>
                            <span>{data.section_title.text}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className='container pb-3 container--sub-section'>
                <div className="row sub-section-wrapper pb-0 pb-lg-5">
                    {items.map((item, index) => {
                        return (
                            <div key={`col-${index}`} className={'col-lg-3 whats-my-wine-item px-3 slide-up animation-delay-' + (index+1)}>
                                <div className='item-img mb-3 d-flex justify-content-center align-items-center'>
                                    {(item.sub_section_image.url)?
                                    <img src={item.sub_section_image.url} width="100%" alt={(item.sub_section_image.alt)? item.sub_section_image.alt : item.sub_section_title.text} />
                                    :null}
                                </div>
                                <div className='item-content pb-5 pb-lg-0'>
                                    <h3 className='mt-4 font-title'>{item.sub_section_title.text}</h3>
                                    <p className='desc mt-2'>
                                        {item.sub_section_description.text}
                                    </p>
                                    {(item.sub_section_button_link.url && item.sub_section_button_text.text)?
                                    <a href={item.sub_section_button_link.url} target={(item.sub_section_button_link.target)? item.sub_section_button_link.target : '_self'}>
                                        <button className='btn btn-primary-2'>
                                            {item.sub_section_button_text.text}
                                        </button>
                                    </a>
                                    :null}
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </section>
    )
}