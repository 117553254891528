import React, { useEffect } from 'react'
import { graphql } from 'gatsby'
import { SliceZone } from '@prismicio/react'

import Layout from '../components/Layout'
import { Seo } from '../components/Seo'
import { components } from '../slices/find-wine-by-details'

//components
import FindYourWineBanner from '../components/FindWineByDetailsBanner'

//preview
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";

const FindWineByDetails = ( props ) => {
    const {data} = props

    const pageData = data.prismicFindWineByDetailsPage.data
    var sliceData = pageData.body

    useEffect(() => {
      //Scroll to the top on new page
      document.documentElement.style.scrollBehavior = 'auto'
      window.scrollTo(0,0)
    }, [])

    return(
        <Layout currentPage="find-wine-by" customPage={true}>
            <Seo
              title={(pageData.meta_title)? pageData.meta_title.text : null}
              description={(pageData.meta_description)? pageData.meta_description.text : null}
            />
            <FindYourWineBanner
              title={pageData.banner_title.text}
              backgroundUrl={pageData.banner_image.url}
              description={pageData.banner_description.html}
              mobileBackgroundUrl={pageData.banner_image_mobile.url}
            />
            <SliceZone slices={sliceData} components={components} />
        </Layout>
    )
}

export default withPrismicPreview(FindWineByDetails)

export const query = graphql`
    query FindWineByDetailsQuery($uid: String) {
        prismicFindWineByDetailsPage(uid: {eq: $uid}) {
            _previewable
            data {
              banner_description {
                html
              }
              banner_image {
                alt
                url
              }
              banner_image_mobile {
                alt
                url
              }
              body {
                ... on PrismicFindWineByDetailsPageDataBodyFindWinesPerfectForAnyOccasion {
                  id
                  slice_type
                  items {
                    sub_section_button_link {
                      slug
                      url
                      target
                    }
                    sub_section_button_text {
                      text
                    }
                    sub_section_description {
                      html
                      text
                    }
                    sub_section_image {
                      alt
                      url
                    }
                    sub_section_title {
                      text
                    }
                  }
                  primary {
                    section_title {
                      text
                    }
                  }
                }
                ... on PrismicFindWineByDetailsPageDataBodyThePerfectWineFor {
                  id
                  slice_type
                  primary {
                    section_button_link {
                      url
                      slug
                      target
                    }
                    section_button_text {
                      text
                    }
                    section_description {
                      html
                      text
                    }
                    section_image {
                      url
                      alt
                    }
                    section_subtitle {
                      text
                    }
                    section_title {
                      text
                    }
                  }
                }
              }
              banner_title {
                text
              }
              meta_description {
                text
              }
              meta_title {
                text
              }
            }
        }
    }
`