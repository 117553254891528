import React, {useRef, useState, useEffect} from 'react'
import { Link } from 'gatsby'

import { isElementInViewport } from '../../components/functions'

export const ThePerfectWineFor = ({ slice }) => {
    const [isInView, setIsInView] = useState(false)
    const ref = useRef(null)

    const data = slice.primary;

    //create an event listener
    useEffect(() => {
        if(isElementInViewport(ref.current)){
            setIsInView(isElementInViewport(ref.current))
        }
        window.addEventListener('scroll',() => {
            if(isElementInViewport(ref.current)){
                setIsInView(true)
            }
        })
    }, [])

    return(
        <section 
            ref={ref}
            className={(isInView)? 'the-perfect-wine-for pt-5 pt-lg-0 in-view' : 'the-perfect-wine-for pt-5 pt-lg-0'}>
            <div className='row p-0 m-0'>
                <div className='col-lg-6 pt-5 pb-5 pb-lg-0'>
                    <div className='content-wrapper slide-up d-flex flex-column justify-content-center'>
                        {(data.section_subtitle.text)?
                            <span className='subtlt-wrapper pb-3'>{data.section_subtitle.text}</span>
                        : null}
                        {(data.section_title.text)?
                            <h2 className='pb-3'>{data.section_title.text}</h2>
                        : null}
                         {(data.section_description)?
                            <div className='desc-wrapper' dangerouslySetInnerHTML={{__html: data.section_description.html}} />
                        : null}

                        {(data.section_button_link.url && data.section_button_text.text)?
                            (data.section_button_link.link_type === "Document" && data.section_button_link.uid)?
                            <Link to={"/" + data.section_button_link.uid}>
                                <button className='btn btn-primary-3'>
                                    <span>{data.section_button_text.text}</span>
                                </button>
                            </Link>
                            :
                            <a href={data.section_button_link.url} title={data.section_button_text.text}>
                                <button className='btn btn-primary-3'>
                                    <span>{data.section_button_text.text}</span>
                                </button>
                            </a>
                        : null}
                    </div>
                </div>
                <div className='col-lg-6 img-wrapper overflow-hidden p-0'>
                    {(data.section_image.url)?
                        <img src={data.section_image.url} alt={(data.section_image.alt)? data.section_image.alt : "Who we are"} width="100%" className='zoom-out-wo-opacity'/>
                    : null}
                </div>
            </div>
        </section>
    )
}